import { Card, Form, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { isAdmin, isBGM, isDistributor } from 'common/config/acl'
import { authorizer } from 'common/config/permissions'
import { HOME } from 'common/config/routes'
import CardPageTitle from 'components/CardPageTitle'
import DistributorSelect from 'components/DistributorSelect'
import ErrorAlert from 'components/ErrorAlert'
import useACL from 'hooks/useACL'
import useGetDistributorID from 'hooks/useGetDistributorID'
import {
  IAwaitingPaymentDistributorPO,
  ICreatedOrder,
  ICreatedVigoQuotation,
  IRecommendedPurchaseRequest,
  OverViewPendingReport,
} from 'interfaces/overviewReport'
import moment from 'moment'
import { useTranslations } from 'next-intl'
import { stringify } from 'qs'
import { useCallback, useEffect, useState } from 'react'
import axiosInstance from 'utils/axiosInstance'
import {
  defaultStringifyOption,
  getResponseData,
  getResponseErrorMessage,
  isIndia,
} from 'utils/commonUtils'
import { ENTITY_TYPE_DISTRIBUTOR_PO } from 'utils/paymentRequest'

import NotificationHomePage from './Notification'
import Shortcuts from './Shortcuts'
import UpcomingDebt from './UpcomingDebt'

const HomePage = () => {
  const translate = useTranslations(HOME)
  const [loading, setLoading] = useState(false)
  const [distributorSelected, setDistributorSelected] = useState('')
  const [error, setError] = useState('')
  const [form] = useForm()
  const { distributorID } = useGetDistributorID()
  const [overviewPurchaseRequests, setOverviewPurchaseRequests] =
    useState<IRecommendedPurchaseRequest>()
  const [overviewVigoQuotations, setOverviewVigoQuotations] =
    useState<ICreatedVigoQuotation>()
  const [overviewPurchaseOrders, setOverviewPurchaseOrders] =
    useState<IAwaitingPaymentDistributorPO>()
  const [overviewOrders, setOverviewOrders] = useState<ICreatedOrder>()
  const [overviewPaymentRequests, setOverviewPaymentRequests] =
    useState<OverViewPendingReport>()

  console.log({ user: authorizer.user })

  const { userRoles } = useACL()

  // Get total recommended Purchase Request
  const getOverviewPurchaseRequests = useCallback(
    async (distributorID?: string) => {
      try {
        setLoading(true)
        const params = stringify(
          {
            from: moment().subtract(6, 'months').startOf('day').toISOString(),
            distributorID,
          },
          defaultStringifyOption
        )
        const response = await axiosInstance.get(
          `/v1/purchase-requests/overview-reports?${params}`
        )
        const data = getResponseData<IRecommendedPurchaseRequest>(response)
        setOverviewPurchaseRequests(data)
      } catch (error) {
        const err = getResponseErrorMessage(error)
        console.log('🚀 ~ err', err)
        setLoading(false)
      } finally {
        setLoading(false)
      }
    },
    [distributorID]
  )

  // Get total created Vigo Quotation
  const getOverviewVigoQuotations = useCallback(
    async (distributorID: string) => {
      try {
        setLoading(true)
        const params = stringify(
          {
            from: moment().subtract(6, 'months').startOf('day').toISOString(),
            distributorID,
          },
          defaultStringifyOption
        )
        const response = await axiosInstance.get(
          `/v1/vigo-quotations/overview-reports?${params}`
        )
        const data = getResponseData<ICreatedVigoQuotation>(response)
        setOverviewVigoQuotations(data)
      } catch (error) {
        const err = getResponseErrorMessage(error)
        console.log('🚀 ~ err', err)
        setLoading(false)
      } finally {
        setLoading(false)
      }
    },
    [distributorID]
  )

  // Get total awaiting payment Distributor Purchase Orders
  const getOverviewPurchaseOrders = useCallback(
    async (distributorID: string) => {
      try {
        setLoading(true)
        const params = stringify(
          {
            from: moment().subtract(6, 'months').startOf('day').toISOString(),
            distributorID,
          },
          defaultStringifyOption
        )
        const response = await axiosInstance.get(
          `/v1/distributor-purchase-orders/overview-reports?${params}`
        )
        const data = getResponseData<IAwaitingPaymentDistributorPO>(response)
        setOverviewPurchaseOrders(data)
      } catch (error) {
        const err = getResponseErrorMessage(error)
        console.log('🚀 ~ err', err)
        setLoading(false)
      } finally {
        setLoading(false)
      }
    },
    [distributorID]
  )

  // Get total awaiting payment Distributor Purchase Orders
  const getOverviewPaymentRequest = useCallback(
    async (distributorID?: string) => {
      try {
        setLoading(true)
        const params = stringify(
          {
            from: moment().subtract(12, 'months').startOf('day').toISOString(),
            ...(distributorID && {
              distributorID,
              entityType: ENTITY_TYPE_DISTRIBUTOR_PO,
            }),
            includeReportDebt: true,
          },
          defaultStringifyOption
        )

        const response = await axiosInstance.get(
          `/v1/payment-requests/overview-reports?${params}`
        )
        const data = getResponseData<OverViewPendingReport>(response)
        setOverviewPaymentRequests(data)
      } catch (error) {
        const err = getResponseErrorMessage(error)
        console.log('🚀 ~ err', err)
        setLoading(false)
      } finally {
        setLoading(false)
      }
    },
    [distributorID]
  )

  // Get total created Orders
  const getOverviewOrders = useCallback(async (distributorID: string) => {
    try {
      setLoading(true)
      const params = stringify(
        {
          from: moment().subtract(6, 'months').startOf('day').toISOString(),
          distributorID,
        },
        defaultStringifyOption
      )
      const response = await axiosInstance.get(
        `/v1/orders/overview-reports?${params}`
      )
      const data = getResponseData<ICreatedOrder>(response)
      setOverviewOrders(data)
    } catch (error) {
      const err = getResponseErrorMessage(error)
      console.log('🚀 ~ err', err)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (distributorID && !isIndia) {
      getOverviewPurchaseRequests(distributorID)
      getOverviewVigoQuotations(distributorID)
      getOverviewPurchaseOrders(distributorID)
      getOverviewOrders(distributorID)
      getOverviewPaymentRequest(distributorID)
    }
    if (!distributorID && distributorSelected && !isIndia) {
      getOverviewPurchaseRequests(distributorSelected)
      getOverviewVigoQuotations(distributorSelected)
      getOverviewPurchaseOrders(distributorSelected)
      getOverviewOrders(distributorSelected)
      getOverviewPaymentRequest(distributorSelected)
    }
    if (!distributorID && !distributorSelected && !isIndia) {
      getOverviewPaymentRequest()
    }
  }, [distributorID, distributorSelected])

  if (
    isIndia ||
    (!isIndia &&
      !isAdmin(userRoles) &&
      !isBGM(userRoles) &&
      !isDistributor(userRoles))
  )
    return (
      <div className="grid grid-cols-4 gap-4">
        <div
          className="bg-white p-4 col-span-3 ml-4 rounded-lg"
          style={{ height: 'calc(100vh - 180px)' }}
        >
          <h2 className="text-lg font-medium">
            {isIndia ? 'Vigo OMS Dashboard' : translate('homePage')}
          </h2>
        </div>
        <NotificationHomePage translate={translate} />
      </div>
    )

  return (
    <div className="grid grid-cols-4 gap-4">
      <div className="col-span-3">
        <Spin spinning={loading}>
          <Card
            title={<CardPageTitle title={translate('homePage')} />}
            className="rounded-lg "
          >
            <div className="w-full overflow-x-auto ">
              {error && (
                <div className="mb-4">
                  <ErrorAlert error={error} onClose={() => setError('')} />
                </div>
              )}
              {/* Filters */}
              {!distributorID && (
                <Form
                  layout="horizontal"
                  form={form}
                  name="distributorSelectForm"
                  className="mb-6"
                >
                  <Form.Item
                    className="mb-0 w-1/2"
                    label={translate('distributor')}
                    name="distributorID"
                    getValueFromEvent={(distributorID) => {
                      setDistributorSelected(distributorID)
                      return distributorID
                    }}
                  >
                    <DistributorSelect
                      placeholder={translate('placeholderDistributor')}
                    />
                  </Form.Item>
                </Form>
              )}
              {(distributorID || distributorSelected) && (
                <Shortcuts
                  totalRecommendedPurchaseRequest={
                    overviewPurchaseRequests?.totalRecommended || 0
                  }
                  totalCreatedVigoQuotation={
                    overviewVigoQuotations?.totalCreated || 0
                  }
                  totalAwaitingPaymentDPO={
                    overviewPurchaseOrders?.totalAwaitingPayment || 0
                  }
                  totalCreatedOrder={overviewOrders?.totalCreated || 0}
                  translate={translate}
                  distributorSelected={distributorSelected}
                />
              )}
            </div>
          </Card>
        </Spin>
      </div>
      <div>
        <NotificationHomePage translate={translate} />
        <UpcomingDebt
          overviewPaymentRequests={overviewPaymentRequests}
          distributorID={distributorID || distributorSelected}
        />
      </div>
    </div>
  )
}

HomePage.auth = {
  acceptedRoles: ['*'],
}

export default HomePage
