import { User } from '@sentry/nextjs'
import { Authorizer } from 'casbin.js'
import { isIndia } from 'utils/commonUtils'

import {
  ADMIN_MARKETPLACE,
  ADMIN_ROLE,
  BEE,
  BGM,
  BRAND_MANAGER,
  CATEGORY_MAN,
  CONTENT,
  DISTRIBUTOR_STAFF,
  EXTERNAL_DISTRIBUTOR,
  EXTERNAL_SUPPLIER,
  FINANCE_ROLE,
  HUB_MANAGER,
  LOGISTICS_MANAGER,
  MARKETING_ROLE,
  MASTER_DISTRIBUTOR_HUB,
  PO_MANAGER,
  SALE_SUPERVISOR,
  SOURCING_MANAGER,
} from './acl'
import {
  AGENT,
  AGENT_COLLECTION,
  AGENT_GROUP,
  AGENT_MANAGEMENT,
  BIDDING_REQUEST,
  BUY_IN_INVOICE,
  CONFIG_CM3_TARGET,
  CONSOLIDATED_PURCHASE_REQUEST,
  CREDIT_DEBIT_NOTE,
  DISTRIBUTOR_LIST,
  DISTRIBUTOR_LIST_VN,
  DISTRIBUTOR_MANAGEMENT,
  DISTRIBUTOR_MARGIN,
  DISTRIBUTOR_PROFILE,
  DISTRIBUTOR_PURCHASE_REQUEST,
  DISTRIBUTOR_REGISTER,
  FINANCE,
  FULFILLMENTS,
  HOME,
  INBOUND_LIST,
  INCENTIVES_CONFIG,
  INVENTORY_LIST,
  INVENTORY_RECOMMEND,
  LOGISTICS,
  MASTER_DATA,
  MENU_PRODUCT,
  NOTIFICATION,
  ORDER_LIST,
  PACKAGE_LIST,
  PARTNER,
  PAYMENT_REQUEST,
  PAYOUT_STATEMENT,
  PRE_ORDERS,
  PRICE_TYPE_CONFIG,
  PROCUREMENT_CONFIG,
  PRODUCT_AVAILABLE,
  PRODUCT_LIST,
  PRODUCT_LIST_VN,
  PRODUCT_LISTING,
  PRODUCT_PRE_ORDER,
  PRODUCT_PRE_ORDER_INDIA,
  PRODUCT_TRADING_BY_LISTING,
  PURCHASE_ORDER_LIST,
  PURCHASING,
  PURCHASING_PURCHASE_REQUEST,
  REASON_CANCEL,
  REMITTANCE_LIST,
  REPORT,
  REPORT_RIDER,
  RETAIL_PURCHASE_REQUEST_LIST,
  RETURN,
  RETURN_ORDER_LIST,
  SELL_OUT_INVOICE,
  SETTINGS,
  SUBSIDY_RULE,
  SUPPLIER_LIST,
  SUPPLIER_LIST_VN,
  SUPPLIER_PORTAL,
  SUPPLIER_PRODUCT_LIST,
  SUPPLIER_PRODUCT_LIST_VN,
  TASK_LIST,
  TAX_MANAGEMENT,
  TRADE_ORDERS,
  TRANSPORTATION_VENDOR,
  USER_LIST,
  VIGO_DELIVERY,
  VIGO_MASTER_QUOTATION,
  VIGO_PURCHASE_ORDER,
  WAREHOUSE_LIST,
  WAREHOUSE_WMS_LIST,
  WMS,
} from './routes'

const RBAC = {
  [HOME]: { read: {} },

  // Notification
  [NOTIFICATION]: { read: {} },

  // Master Data
  [MASTER_DATA]: { read: {} },
  [WAREHOUSE_LIST]: { read: {}, edit: {}, create: {}, delete: {} },
  [SUPPLIER_LIST]: {
    read: {},
    edit: {
      editSupplier: [ADMIN_ROLE, SOURCING_MANAGER],
    },
    create: {
      createSupplier: [ADMIN_ROLE, SOURCING_MANAGER],
    },
    delete: {
      deleteSupplier: [ADMIN_ROLE, SOURCING_MANAGER],
    },
  },
  [DISTRIBUTOR_LIST]: { read: {}, edit: {}, create: {}, delete: {} },
  [DISTRIBUTOR_MARGIN]: { read: {}, edit: {}, create: {} },
  [PRODUCT_LIST]: { read: {}, edit: {}, create: {}, delete: {} },
  [SUPPLIER_PRODUCT_LIST]: { read: {}, edit: {}, create: {}, delete: {} },
  [PRODUCT_PRE_ORDER_INDIA]: { read: {}, edit: {}, create: {} },

  // Product
  [MENU_PRODUCT]: { read: {} },
  [PRODUCT_AVAILABLE]: {
    read: {},
    create: {
      createProduct: [
        ADMIN_ROLE,
        BGM,
        SOURCING_MANAGER,
        BRAND_MANAGER,
        EXTERNAL_SUPPLIER,
        PO_MANAGER,
        EXTERNAL_DISTRIBUTOR,
        SALE_SUPERVISOR,
        BEE,
      ],
    },
  },
  [PRODUCT_LIST_VN]: {
    read: {
      export: [
        ADMIN_ROLE,
        BGM,
        EXTERNAL_DISTRIBUTOR,
        FINANCE_ROLE,
        SOURCING_MANAGER,
        SALE_SUPERVISOR,
        CATEGORY_MAN,
      ],
    },
    edit: {},
    create: {},
    delete: {},
  },
  [PRODUCT_LISTING]: { read: {}, edit: {}, create: {} },
  [SUPPLIER_PRODUCT_LIST_VN]: { read: {}, edit: {}, create: {}, delete: {} },
  [PRODUCT_TRADING_BY_LISTING]: {
    read: {
      rowSelection: [
        ADMIN_ROLE,
        EXTERNAL_DISTRIBUTOR,
        BGM,
        SALE_SUPERVISOR,
        FINANCE_ROLE,
        CATEGORY_MAN,
        BEE,
      ],

      export: [],
    },

    edit: {
      terminate: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, BGM, SALE_SUPERVISOR, BEE],
      needAdjust: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, BGM, SALE_SUPERVISOR, BEE],
      approval: [ADMIN_ROLE, FINANCE_ROLE, BEE],
      editProduct: [
        ADMIN_ROLE,
        BGM,
        EXTERNAL_DISTRIBUTOR,
        FINANCE_ROLE,
        SOURCING_MANAGER,
        SALE_SUPERVISOR,
        BEE,
      ],
      update: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, BGM, SALE_SUPERVISOR, BEE],
    },
    create: {
      createProduct: [
        ADMIN_ROLE,
        EXTERNAL_DISTRIBUTOR,
        BGM,
        SALE_SUPERVISOR,
        BEE,
      ],
    },
    delete: {},
  },
  [PRODUCT_PRE_ORDER]: {
    read: {
      import: [ADMIN_ROLE, BEE],
      export: [ADMIN_ROLE, CATEGORY_MAN, BEE],
    },
    edit: {
      editProduct: [ADMIN_ROLE, SOURCING_MANAGER, BEE],
      editStatus: [ADMIN_ROLE, SOURCING_MANAGER, BEE],
    },
    create: {
      createProduct: [ADMIN_ROLE, SOURCING_MANAGER, BEE],
    },
  },

  // Partner
  [PARTNER]: { read: {} },
  [SUPPLIER_LIST_VN]: {
    read: {},
    edit: {
      editSupplier: [ADMIN_ROLE, SOURCING_MANAGER, BEE],
    },
    create: {
      createSupplier: [ADMIN_ROLE, SOURCING_MANAGER, BEE],
    },
    delete: {
      deleteSupplier: [ADMIN_ROLE, SOURCING_MANAGER, BEE],
    },
  },
  //Distributor Center
  [PURCHASING]: {
    actions: {
      overViewPurchaseRequest: [
        ADMIN_ROLE,
        EXTERNAL_DISTRIBUTOR,
        BGM,
        SALE_SUPERVISOR,
        CATEGORY_MAN,
        BEE,
      ],
      overViewVigoQuotation: [
        ADMIN_ROLE,
        EXTERNAL_DISTRIBUTOR,
        SALE_SUPERVISOR,
        CATEGORY_MAN,
        BEE,
      ],
      overViewPaymentRequest: [
        ADMIN_ROLE,
        EXTERNAL_DISTRIBUTOR,
        CATEGORY_MAN,
        BEE,
      ],
      overViewPurchaseOrder: [
        ADMIN_ROLE,
        EXTERNAL_DISTRIBUTOR,
        CATEGORY_MAN,
        BEE,
      ],
    },
    edit: {},
    create: {},
    delete: {},
  },
  [PURCHASING_PURCHASE_REQUEST]: {
    read: {
      visibleViewReason: [
        ADMIN_ROLE,
        EXTERNAL_DISTRIBUTOR,
        BGM,
        SOURCING_MANAGER,
        CATEGORY_MAN,
      ],
    },
    edit: {},
    create: {
      createPurchaseRequest: [
        ADMIN_ROLE,
        EXTERNAL_DISTRIBUTOR,
        BGM,
        SALE_SUPERVISOR,
        BEE,
      ],
      createBulkPurchaseRequest: [ADMIN_ROLE, BGM, SALE_SUPERVISOR],
    },
    delete: {},
  },
  [VIGO_MASTER_QUOTATION]: {
    read: {},
    edit: {},
    create: {},
    delete: {},
  },

  [VIGO_PURCHASE_ORDER]: {
    read: {},
    edit: {
      confirmPurchaseOrder: [
        ADMIN_ROLE,
        PO_MANAGER,
        SOURCING_MANAGER,
        LOGISTICS_MANAGER,
        FINANCE_ROLE,
      ],
      rejectPurchaseOrder: [
        ADMIN_ROLE,
        PO_MANAGER,
        SOURCING_MANAGER,
        LOGISTICS_MANAGER,
        FINANCE_ROLE,
      ],
    },
    create: {},
    delete: {},
  },
  [INVENTORY_RECOMMEND]: {
    actions: {
      import: [ADMIN_ROLE, SOURCING_MANAGER, BEE],
      export: [ADMIN_ROLE, SOURCING_MANAGER, BEE],
    },
  },
  [REASON_CANCEL]: {
    read: {},
    edit: {
      editStatus: [ADMIN_ROLE, BEE],
    },
    create: {
      createReasonCancel: [ADMIN_ROLE, BEE],
    },
    delete: {},
  },
  [CONFIG_CM3_TARGET]: {
    actions: {
      export: [ADMIN_ROLE, SOURCING_MANAGER, CATEGORY_MAN, BEE],
      import: [ADMIN_ROLE, SOURCING_MANAGER, BEE],
    },
    edit: {},
    create: {},
    delete: {},
  },

  // Agent
  [AGENT]: { read: {} },
  [AGENT_MANAGEMENT]: {
    read: {},
    actions: {
      create: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, SALE_SUPERVISOR],
      edit: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, SALE_SUPERVISOR],
      export: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, SALE_SUPERVISOR],
      link: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, SALE_SUPERVISOR],
      unlink: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, SALE_SUPERVISOR],
    },
  },
  [AGENT_COLLECTION]: {
    read: {},
    actions: {
      collected: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, SALE_SUPERVISOR],
      massiveCollect: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, SALE_SUPERVISOR],
      reject: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, SALE_SUPERVISOR],
      export: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, SALE_SUPERVISOR, BEE],
    },
  },

  //procurement
  [DISTRIBUTOR_PURCHASE_REQUEST]: {
    actions: {
      create: [ADMIN_ROLE, SOURCING_MANAGER, BEE],
      export: [ADMIN_ROLE, SOURCING_MANAGER, BEE],
      edit: [ADMIN_ROLE, SOURCING_MANAGER, BEE],
    },
  },
  [CONSOLIDATED_PURCHASE_REQUEST]: {
    actions: {
      rejectSourcingTeam: [ADMIN_ROLE, SOURCING_MANAGER, BEE],
      submitSourcingTeam: [ADMIN_ROLE, SOURCING_MANAGER, BEE],
      updateSourcingTeam: [ADMIN_ROLE, SOURCING_MANAGER, BEE],
      rejectGrowthTeam: [ADMIN_ROLE, SALE_SUPERVISOR, BGM],
      submitGrowthTeam: [ADMIN_ROLE, SALE_SUPERVISOR, BGM],
      updateGrowthTeam: [ADMIN_ROLE, SALE_SUPERVISOR, BGM],
    },
  },

  // Distributor Profile
  [DISTRIBUTOR_MANAGEMENT]: { read: {} },
  [DISTRIBUTOR_PROFILE]: {
    actions: {
      create: [ADMIN_ROLE, BEE],
      edit: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, BEE],
      delete: [ADMIN_ROLE],
    },
  },
  [DISTRIBUTOR_LIST_VN]: { read: {}, edit: {}, create: {}, delete: {} },
  [DISTRIBUTOR_REGISTER]: { actions: { edit: [ADMIN_ROLE, BEE] } },

  // Supplier Portal
  [SUPPLIER_PORTAL]: { read: {} },
  [RETAIL_PURCHASE_REQUEST_LIST]: { read: {}, edit: {} },
  [PURCHASE_ORDER_LIST]: { read: {}, edit: {}, create: {} },
  [BIDDING_REQUEST]: { read: {}, edit: {}, create: {}, delete: {} },

  // WMS
  [WMS]: { read: {} },
  [WAREHOUSE_WMS_LIST]: {
    actions: {
      create: [ADMIN_ROLE, BEE],
      edit: [ADMIN_ROLE, BEE],
      delete: [ADMIN_ROLE],
    },
  },
  [INBOUND_LIST]: {
    actions: {
      print: [
        ADMIN_ROLE,
        EXTERNAL_DISTRIBUTOR,
        PO_MANAGER,
        FINANCE_ROLE,
        CONTENT,
        BGM,
        MASTER_DISTRIBUTOR_HUB,
        BEE,
      ],
      disableEditBaseInfo: [ADMIN_MARKETPLACE, CATEGORY_MAN],
    },
  },
  [INVENTORY_LIST]: { read: {}, edit: {}, create: {} },
  //Rider
  [PACKAGE_LIST]: {
    actions: {
      export: [
        ADMIN_ROLE,
        EXTERNAL_DISTRIBUTOR,
        FINANCE_ROLE,
        SALE_SUPERVISOR,
        DISTRIBUTOR_STAFF,
        CATEGORY_MAN,
        BEE,
      ],
      confirmDelivery: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, SALE_SUPERVISOR, BEE],
    },
  },
  [REMITTANCE_LIST]: {
    actions: {
      create: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, BEE],
    },
  },
  //Marketing
  [SUBSIDY_RULE]: {
    actions: {
      create: [
        ADMIN_ROLE,
        BGM,
        FINANCE_ROLE,
        EXTERNAL_DISTRIBUTOR,
        SOURCING_MANAGER,
        SALE_SUPERVISOR,
      ],
      cancel: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR],
    },
  },
  [INCENTIVES_CONFIG]: {
    actions: {
      create: [ADMIN_ROLE, MARKETING_ROLE],
    },
  },
  [AGENT_GROUP]: {
    actions: {
      create: [ADMIN_ROLE, MARKETING_ROLE],
    },
  },

  // Settings
  [SETTINGS]: { read: {} },
  [USER_LIST]: {
    actions: {
      create: [ADMIN_ROLE],
      edit: [ADMIN_ROLE],
      delete: [ADMIN_ROLE],
    },
  },
  [TASK_LIST]: {
    actions: {
      disableImport: [DISTRIBUTOR_STAFF, CATEGORY_MAN],
    },
  },
  [PROCUREMENT_CONFIG]: {
    actions: {
      create: [ADMIN_ROLE],
    },
  },

  // Fulfillment
  [FULFILLMENTS]: { read: {} },
  [ORDER_LIST]: {
    actions: {
      cancelOrder: isIndia
        ? [
            ADMIN_ROLE,
            EXTERNAL_DISTRIBUTOR,
            ADMIN_MARKETPLACE,
            DISTRIBUTOR_STAFF,
          ]
        : [
            ADMIN_ROLE,
            EXTERNAL_DISTRIBUTOR,
            BGM,
            DISTRIBUTOR_STAFF,
            SALE_SUPERVISOR,
            HUB_MANAGER,
            BEE,
          ],
    },
  },
  [PRE_ORDERS]: {
    actions: {
      visibleButton: isIndia
        ? [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, BGM, PO_MANAGER]
        : [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, BGM, SALE_SUPERVISOR, BEE],
    },
  },
  [TRADE_ORDERS]: {
    actions: {
      disableExport: [ADMIN_MARKETPLACE],
      disableCancel: [ADMIN_MARKETPLACE, CATEGORY_MAN],
    },
  },
  [PAYOUT_STATEMENT]: {
    actions: {
      export: [ADMIN_ROLE, FINANCE_ROLE, BGM, MARKETING_ROLE, BEE],
      import: [ADMIN_ROLE, FINANCE_ROLE, BGM, MARKETING_ROLE],
      massApprove: [ADMIN_ROLE, FINANCE_ROLE, BGM, MARKETING_ROLE],
      massReject: [ADMIN_ROLE, FINANCE_ROLE, BGM, MARKETING_ROLE],
    },
  },

  // FINANCE_ROLE
  [FINANCE]: { read: {} },
  [BUY_IN_INVOICE]: {
    actions: {
      export: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, FINANCE_ROLE, BEE],
      download: [ADMIN_ROLE, EXTERNAL_DISTRIBUTOR, FINANCE_ROLE, BEE],
    },
  },
  [SELL_OUT_INVOICE]: { read: {}, edit: {}, create: {} },
  [PAYMENT_REQUEST]: {
    actions: {
      cancel: [ADMIN_ROLE, FINANCE_ROLE],
      markAsPaid: [ADMIN_ROLE, FINANCE_ROLE],
      addPhase: [ADMIN_ROLE, FINANCE_ROLE],
      recordDebt: [ADMIN_ROLE, FINANCE_ROLE],
      update: [ADMIN_ROLE, FINANCE_ROLE],
    },
  },
  [PRICE_TYPE_CONFIG]: {
    actions: {
      create: [ADMIN_ROLE, FINANCE_ROLE],
      edit: [ADMIN_ROLE, FINANCE_ROLE],
    },
  },
  [TAX_MANAGEMENT]: {
    actions: {
      import: [ADMIN_ROLE, FINANCE_ROLE],
      export: [ADMIN_ROLE, FINANCE_ROLE],
      edit: [ADMIN_ROLE, FINANCE_ROLE],
    },
  },
  // Report
  [REPORT]: { read: {} },
  [REPORT_RIDER]: { read: {} },

  // Logistics
  [LOGISTICS]: { read: {} },
  [TRANSPORTATION_VENDOR]: {
    read: {},
    actions: { create: [ADMIN_ROLE, LOGISTICS_MANAGER, BEE] },
  },
  [VIGO_DELIVERY]: {
    actions: {
      editStatus: [ADMIN_ROLE, LOGISTICS_MANAGER, BEE],
      editVigoDelivery: [ADMIN_ROLE, LOGISTICS_MANAGER, BEE],
    },
  },

  // Return
  [RETURN]: { read: {} },
  [RETURN_ORDER_LIST]: { read: {}, edit: {}, create: {}, delete: {} },
  [CREDIT_DEBIT_NOTE]: { read: {}, edit: {}, create: {} },
}

// Set the user's permission:
// He/She can read `data1` and `data2` objects and can write `data1` object
// Run casbin.js in manual mode, which requires you to set the permission manually.
export const authorizer = new Authorizer('manual')

export default function defineRulesFor(user: User, pathname: string) {
  // superUser roles definition
  const builtPerms: Record<string, any> = {}

  // perms should be of format
  // { 'read': ['Contact', 'Database']}
  user.roles.forEach((role) => {
    const permissions = RBAC[pathname as keyof typeof RBAC]
    Object.entries(permissions).forEach(([key, value]) => {
      let rules = []
      Object.entries(value).forEach(([action, v]: [string, string[]]) => {
        if (v && v.includes(role)) {
          rules = [...rules, action]
        }
      })
      builtPerms[key] = [...(builtPerms[key] || []), ...rules]
    })
  })

  authorizer.setPermission(builtPerms)
}
